<template>

    <div class="tagInput">

   
        <vue-tags-input
                v-model="tag"
                :tags="tags"
                @tags-changed="newTags => updateTags(newTags)"
                :placeholder="inputPlaceholder"
        />

        <input 
               type="hidden"
               v-model="tagString"
               :name="inputName"
        />
        
    </div> <!-- .tagitField -->
    
</template>

<script>

export default {

    name: 'mtcTagInput',
    props: {
        inputPlaceholder: {
            type: [String],
            default (rawProps) {
                return 'Add tag';
            }
        },
        inputName: {
            type: [String],
            default (rawProps) {
                return 'tags';
            }
        },
        autocompleteItems: {
            type: [Array, Object],
            default (rawProps) {
                return [];
            }
        },
        tagsString: {
            type: [String, Boolean],
            default (rawProps) {
                return '';
            }
        }
    },
    computed: {
      
    },
    data() {
        return {
            tag: '',
            tags: [],
            tagString: '',
            autoCompleteArray: []
        }
    },
    created() {
        this.tagString = this.tagsString;
        this.tags = this.stringToTags(this.tagString);
    },
    methods: {

        updateTags(newTags) {
            
            let str = '';

            newTags.forEach((tag, i) => {
                
                if(i > 0) {
                    str += ',';
                }
                
                str += tag.text;
                
            });
            
            this.tagString = str;
        },
        
        stringToTags(inputString) {
            
            if(typeof inputString == 'undefined') {
                return;
            }
            
            if(!inputString.length) {
                inputString = this.defaultTags;
            }
            
            let tags = [];
            
            if(inputString.length) {
                let splitTags = inputString.split(',');
                
                splitTags.forEach(tag => {

                    tags.push({
                        text: tag
                    });
                    
                });
            }
            
            return tags;
            
        },
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .ti-input {
        
        .ti-tags {
            border-radius: 59px;
        }
        
    }

</style>
