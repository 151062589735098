var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "browsePage" },
    [
      _vm.page
        ? [
            _c("div", { staticClass: "titleBanner withImage leftAlign" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c("div", { staticClass: "innerTitleBanner" }, [
                  _c("div", { staticClass: "bannerContentWrap" }, [
                    _c(
                      "div",
                      { staticClass: "innerContentWrapper" },
                      [
                        _c("page-title", {
                          attrs: {
                            title: _vm.page.title,
                            content: _vm.page.title_content,
                            "css-class": "browseTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "imageBackground" }, [
                _c("picture", { staticClass: "imageWrap" }, [
                  _c("img", {
                    attrs: {
                      src: "/sites/default/images/examples/hero-example.png",
                      alt: _vm.page.title,
                      width: "1674",
                      height: "368",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("product-filter", {
            attrs: {
              items: _vm.products,
              filters: _vm.filters,
              loading: _vm.loading,
              "follow-links": _vm.followLinks,
            },
            on: { "update-ajax": _vm.updateAjax },
          }),
          _vm._v(" "),
          _c("product-list", {
            attrs: {
              loading: _vm.loading,
              products: _vm.products,
              "parent-class": ["browseProducts"],
              "list-class": ["itemsBrowse"],
            },
          }),
          _vm._v(" "),
          _c("pagination", {
            attrs: { pagination: _vm.pagination },
            on: { "update-ajax": _vm.updateAjax },
          }),
          _vm._v(" "),
          _c("loading-spinner", {
            attrs: { loading: _vm.loading, "loading-text": "Loading Products" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }