let addedChat = false;


$(document).ready(function () {

    /*
     * force strict mode
     */
    'use strict';


    $('body').addClass('pageReady');

    // Homepage tabs
    if ( $('[data-tabs-title]').length ) {
        const titles = $('[data-tabs-title] li');
        const contents = $('[data-tabs-content] ul');

        titles.on('click', function() {
            titles.removeClass('active');
            $(this).addClass('active');

            const t = $(this).attr('data-target');
            contents.removeClass('active');
            $('[data-tabs-content] ' + t).addClass('active');
        })
    }


    $('.tabsTop li').on('click', function() {

        $('.tabsTop li').removeClass('active');
        $(this).addClass('active');

        const t = $(this).attr('data-target');
        $('.contentBody li').removeClass('active');
        $('.contentBody ' + t).addClass('active');

    });


    /*
    * Scrollto
     */
    if($('.js_scrollTo').length) {
        $('.js_scrollTo').on('click', function(e) {
            e.preventDefault();

            var $target = $($(this).attr('href'));

            if($target.length) {
                $('html,body').animate({
                    scrollTop: $target.offset().top
                }, 1200);
            }
        });
    }

    /*
    * MTC Mobile Menu
    */

    if($('.flyoutWrap').length) {
        $.mtcMobileMenu({
            move_site: false,
            position: 'left',
            is_fixed: true
        });
    }


    if($('.js_sidenavLink').length) {

        $('.js_sidenavLink').on('click', function(e){

            e.preventDefault();

            $(this).closest('ul').find('a').removeClass('active');
            $(this).addClass('active');

            var $target = $($(this).attr('href'));
            $('.tabContentWrap > section').removeClass('active');
            $target.addClass('active');


        });

    }

    /*
    * Category / product side tabs
    */
    if($('.sidebarTabs .sectionTitle').length) {
        showHideMenus('.sidebarTabs .sectionTitle', 700);
        $('.sidebarTabs section').eq(0).find('.sectionTitle').click();
    }

    if($('.sidebarTabs .sideNav').length) {

        var headerHeight = 130;
        
        if($('#header').length) {
            headerHeight = $('#header').outerHeight() + 24;
        }
        
        
        var stuck = $('.sidebarTabs .tabNav ul').stick_in_parent({
            parent: '.sidebarTabs',
            offset_top: headerHeight
        });

    }

    /*
    * Flyout
    */
    $('.flyoutButton').on('click', function () {
        $('.flyoutButton').toggleClass('open');
    });


    /*
     * Global variables
     */

    var viewportMeta = $('meta[name="viewport"]');

/*
 * Html touch class from jquery mobile
 */

    $('html').addClass('noTouchevents');

/*
 * AJAX forms
 */

    // contact form
    if ($('.js_contactForm').length) {
        submitForm('.js_contactForm');
    }


/*
 * Responsive Tables
 */

    if ($('table').hasClass('responsive')) {
        mtcResponsiveTables({
            breakpoint: 640
        });
    }


/*
 * Password Toggle
 */
if($('input[type="password"].passwordToggle').length) {

    var $passwordFields = $('input[type="password"].passwordToggle:not(.toggleSet)');

    $passwordFields.each(function(){

        var $parent = $(this).parent('.inputWrap');
        $(this).addClass('toggleSet');
        $parent.addClass('passwordToggle');

        if(!$parent.find('.toggleButtons').length) {
            $parent.append('<span class="toggleButtons"><i class="fas fa-eye disabled"></i><i class="fas fa-eye-slash enabled"></i></span>')
        }

        $parent.find('.toggleButtons').on('click', function(e) {

            var $field = $(this).parent().find('.toggleSet');

            if($field.length) {
                $field.parent().toggleClass('reveal');

                if ($field.parent().hasClass('reveal')) {
                    $field.attr('type', 'text');
                } else {
                    $field.attr('type', 'password');
                }
            }

        });

    });

}

/*
 * Select box replacement
 */

    if ($('select').length) {

        $('select:not(.no-chosen)').chosen({
            disable_search_threshold: 100
        });

    }

/*
 * Back to top link animation
 */

if($('a.scrollLink').length) {
    $('a.scrollLink').on('click', function (e) {
        e.preventDefault();
        var target = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(target).offset().top
        }, 'slow');
    });
}

/*
 * No HTML5 Validation
 */

    if ($('form').length) {
        $('form').attr('novalidate', 'novalidate');
    }


/*
 * MTC Overlay
 *
 * Allows links to open in an overlay above the window content
 */

    if ($('.mtcOverlay').length) {
        $('.mtcOverlay').mtcOverlay();
    }

/*
 * MTC Overlay for Price Comparison
 *
 * Allows links to open in an overlay above the window content
 */

    if ($('.mtcPriceOverlay').length) {
        $('.mtcPriceOverlay').mtcOverlay({
            onOpen: function() {
                $('.js_tabs').mtcTabs();
            }
        });
    }


/*
 * File Upload Name
 */

    //fileUpload();

/*
 * Datepicker
 */

    if ($('.datePicker').length) {

        var dateDelay = null,
            yearWrapped = false;

        $(".datePicker").datepicker({
            showButtonPanel: true,
            changeYear: true
        });
    }
/*
 * Front page slideshow
 */

    if ($('.sliderWrap').length) {
        slideShow();
    }


    if($('.js_tabs').length) {
        $('.js_tabs').mtcTabs();
    }



    /*
     * Sticky Sidebar
    */
    if($('.stickySidebarWrap').length) {
        $('.stickySidebarWrap').stick_in_parent({
            parent: '.stickyParent',
            offset_top: 130,
        });
    }

   /*
   * Mobile Members Menu
   */
    if($('.js_mobileMembersMenu').length) {
        showHideMenus('.js_mobileMembersMenu', window.breakpoints.flyout);
    }


    /*
    * Trust Bars
    */
    slickTrustLogoBar();

    /*
    * Select box that directs to url
    */
    if($('.js_gotoSelect').length) {

        $('.js_gotoSelect').on('change', function(){

            var val = $.trim($(this).val());

            if(val.length > 0 && val != 'all') {
                window.location = val;
            }

        });

    }


    /*
    * FAQS need more help toggle
    */
    if( $('.faqsHelp').length && $('.js_faqsHelp').length ) {
        $('.faqsHelp').on('change', function() {
            var val = $(this).val();

            if(val == 'No') {
                $('.js_faqsHelp').slideDown();
            }else{
                $('.js_faqsHelp').slideUp();
            }
        });
    }

    /*
    * Hidden category list
    */
    if($('.js_revealList').length) {

        $('.js_revealList').on('click', function(e){

            e.preventDefault();

            var $target = $(this).parent().parent().find('.hiddenList'),
                $btn = $(this);

            if($target.length) {

                $target.slideFadeToggle(function() {

                    if($target.is(':visible')) {
                        $btn.text('Show less');
                    }else{
                        $btn.text('Show more');
                    }

                });
            }

        });

    }

    if($('#launchUserPrompt').length) {
        $('#launchUserPrompt').click();
    }


}); // document ready


$(window).on('resize', debouncer(function (e) {

/*
 * Force strict mode
 */

    'use strict';

/*
 * Set window width on resize
 */

    window.windowWidth = $(window).outerWidth();


    /*
    * Mobile Members Menu
    */
    if($('.js_mobileMembersMenu').length) {
        showHideMenus('.js_mobileMembersMenu', window.breakpoints.flyout);
    }

    /*
    * Trust Bars
    */
    slickTrustLogoBar();

})); // debounce

/*
 * Slick below breakpoint, else remove slick
 */
function slickIt(element_to_slick, breakpoint, slick_settings) {
    'use strict';
    var slider_active = $(element_to_slick).hasClass('slick-initialized');
    if ($(element_to_slick + ' li').length > 1) {

        if (matchesMediaQuery(0, breakpoint)) {
            if (!slider_active) {
                $(element_to_slick).slick(slick_settings);
            }
        } else {
            if (slider_active) {
                $(element_to_slick).slick('unslick');
            }
        }
    }
}


function slickTrustLogoBar() {

    if($('.trustLogoBar').length) {

        slickIt('.trustLogoBar .trustLogos', 1000, {
            dots: false,
            infinite: true,
            arrows: false,
            autoplay: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            speed: 1000,
            //fade: true,
            responsive: [{
                breakpoint: 1001,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }, {
                breakpoint: 481,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }]

        });

    }

}
