import { render, staticRenderFns } from "./stock_badge.vue?vue&type=template&id=6d312172&scoped=true&"
import script from "./stock_badge.vue?vue&type=script&lang=js&"
export * from "./stock_badge.vue?vue&type=script&lang=js&"
import style0 from "./stock_badge.vue?vue&type=style&index=0&id=6d312172&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d312172",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home3/stacure/public_sam/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d312172')) {
      api.createRecord('6d312172', component.options)
    } else {
      api.reload('6d312172', component.options)
    }
    module.hot.accept("./stock_badge.vue?vue&type=template&id=6d312172&scoped=true&", function () {
      api.rerender('6d312172', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sites/default/js/components/shop/item/partials/stock_badge.vue"
export default component.exports