<template>
    
    <article class="productCard" :class="[large ? 'largeCard' : '']">

        <header class="productHeader">
            
            <a :href="productUrl" class="productTitleLink">
                <h2 
                        class="productTitle" 
                        v-text="product.name" 
                        v-if="product.name"
                ></h2>

                <stock-badge
                        :has-stock="inStock"
                        :small="true"
                        v-if="showStock"
                ></stock-badge>

                <div 
                        class="productContent" 
                        v-if="productDescription" 
                        v-html="productDescription"
                ></div> <!-- .productContent -->
                
               
            </a>
            
        </header>
        
        <section class="productImageSection">
            
            <div class="productCardImage" v-if="slideshow">

                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="true"
                        :show-arrows="true"
                        :show-thumbs="false"
                        :allow-zoom="false"
                ></product-images>

            </div> <!-- .productCardImage -->
            
            <a :href="productUrl" class="productCardImage" v-else>
                
                <product-images
                        :images="product.images"
                        :lazy-load="true"
                        size="thumbs"
                        :slideshow="false"
                        :show-arrows="false"
                        :show-thumbs="false"
                        :allow-zoom="false"
                ></product-images>
                
            </a>
            
        </section>
        
        <footer class="productFooter">
            
            <div class="footerBottom">

                <div class="priceWrapper" v-if="fromPriceCalculated">
                    <span
                            class="fromPrefix"
                            v-if="product.sizes?.length"
                    >From</span>
                    <multi-currency-price :price="fromPriceCalculated"/>
                </div> <!-- .priceWrap -->

                <add-to-basket
                        :ajax-add-to-basket="$store.state.minibasket.productCardsQuickAdd"
                        :basket-button-data="basketButtonData" 
                        :browse-page="true"
                        :product="product"
                        :default-size-selected-id="defaultSelectedSizeID"
                ></add-to-basket>
                
            </div> <!-- .footerBottom -->
            
            
        </footer>
        
    </article>

</template>

<script>

import _ from 'lodash';
import travelQuantityAdjust from './partials/travel_quantity_adjust';
import addToBasket from './partials/add_to_basket';
import MtcNumberInput from '../../mtc_number_input';

export default {
    name: "product-card",
    components: {
        addToBasket,
        travelQuantityAdjust,
        MtcNumberInput
    },
    props: {
        large: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        classes: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        //If true, price will be hidden and button will become a link
        minimal: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        fromPrice: {
            type: [Number, Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        showStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        lazyLoad: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        slideshow: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    label: false,
                    url: false
                };
            }
        },
        category: {
            type: [Object, Array, Boolean, String],
            default (rawProps) {
                return false;
            }
        },
        showDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    
    mounted() {
        
        const vm = this;
      
        
    },
    data() {
        return {
            showEffects: false,
            showMedType: false,
            maxDescriptionLength: 600,
            viewProductText: 'View product',
            fallbackImagePath: '/sites/default/images/no-image-items-thumb.png',
            quantityAdjustVisible: false,
            interval: null
        }
    },
    computed: {
        
        fromPriceCalculated() {
            if (typeof this.product.sizes === "undefined" || this.product.sizes.length === 0) {
                return this.product.sale_price > 0 ?
                    this.product.sale_price :
                    this.product.price;
            }
            let minPrice = 0;
            this.product.sizes.forEach(size => {
                let sizePrice = size.sale_price > 0 ?
                    size.sale_price :
                    size.price;
                if (minPrice === 0) {
                    minPrice = sizePrice;
                } else if (sizePrice < minPrice) {
                    minPrice = sizePrice;
                }
            })
            return minPrice;
        },

        productDescription() {
  

            let text = '';
            
            if(this.product?.custom?.description__product_card) {
                text = this.product.custom.description__product_card;
            }else if(this.product?.description) {
                text = this.product.description;

                if(text.length) {

                    let maxLength = this.maxDescriptionLength;

                    let append = '';

                    if(text.length > maxLength) {
                        append = '...';
                    }

                    text = text
                                    .replace(/(<([^>]+)>)/gi, "")
                                    .substring(0,maxLength)
                            + append;
                    
                    return '<p>' + text + '</p>';

                }
            }

            
            if(text.length) {
                return text;
            }
            
            return false;
        },

        productImageSrc() {
            
            if(!this.productImage) {
                return this.fallbackImagePath;
            }
            
            return this.productImage;
        },
        defaultSelectedSizeID() {
            if (
                typeof this.product.recommended_size_id !== 'undefined' &&
                this.product.recommended_size_id > 0
            ) {
                return this.product.recommended_size_id;
            }
            return false;
        }
        
    },
  
    methods: {
        
        
        updateQuantitySelected(qty) {
            
            
            if(!_.isUndefined(qty)) {
                
                qty = parseInt(qty);
                
                if(!isNaN(qty)) {
                    
                    
                    this.product.quantity = qty;
                }
            }
            
            this.quantityAdjustVisible = false;
            
            
            
        }
    }
}

</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    @import '@site/../default/css/base/font.less';
    

    .productCard {
        background: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 35px;
        text-align: left;
        width: 100%;
        height: 100%;
        position: relative;
        border: 1px solid #CACACA;
        padding: 25px 40px;
        

        .innerImage {
            order: 1;
            padding: 0;
        }

        .productHeader {
            order: 2;
            padding: 12px 0 0 0;
            text-align: inherit;
        }

        .priceWrapper {
            padding-top: 0;
            color: @primary_color;

            .fromPrefix {
                display: block;
                .rems(15);
                font-weight: 400;
                line-height: 16px;
            }

            .multiPrice {

                .rems(23);
                font-weight: 700;
                line-height: normal;
            }

        }
        
        .productFooter {
            order: 4;
            padding: 15px 0 0 0;
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            margin-top: auto;
            
            
            .footerBottom {
                padding: 0;
                margin-top: 0;
            }
        }
        
        .productTitleLink {
            color: @primary_color;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .productTitle {
                color: @primary_color;
                transition: color 200ms linear;
            }
            
            
        }
        
        
        a.productTitleLink {

            &:hover, &:active, &:focus-visible {
                .productTitle {
                    color: @primary_color;
                    transition: color 200ms linear;
                }
            }
            
        }

        .productTitle {
       
            margin: 0;
            .rems(24);
            font-weight: 700;
            line-height: normal;
            letter-spacing: normal;
            color: inherit;
            font-family: @primary_font;

        }

        .categoryTitle {
            .rems(16);
            line-height: 1.2em;
            font-weight: 700;
            display: none;
            margin: 0;
        }

        .productImageSection {
            
            max-width: 100%;

            .productImage {

                &, &.largeImages {

                    padding: 0;

                    .mainImage {
                        border: none;
                        border-radius: 0;
                        overflow: hidden;
                        background: #fff;
                        aspect-ratio: 1;
                        width: auto;
                        max-height: 232px;
                    }
                    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }

                }


            }

        }

        .productContent {
            padding: 0;
            .rems(16);
            font-weight: 400;
            line-height: 24px;
            color: #505D68;
            margin: 0;

            
            p {
                color: inherit;
                margin: 0 0 20px;
                
                &:last-child {
                    margin: 0 0 20px;
                }
            }
            
            ul, ol {
                .iconList();
                font-weight: 500;
                margin-bottom: 20px !important;
            }
        }

        .productCta {
            padding: 15px 15px 8px 15px;
            margin-top: auto;

            .button {
                margin: 15px 0 0;
                width: 100%;
            }

        }

        .basketActions {
            
            padding-top: 17px;
            
            .buttonGrid {

                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 100%;
                
                .button {
                    .rems(18);
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.54px;
                    width: 100%;
                    margin: 0;
                    padding-left: 8px;
                    padding-right: 8px;
                }
                
                .buttonItem {
                    
                    flex: 1 1 100%;
                    
                    & + .buttonItem {
                        flex: 0 0 50%;
                    }
                }
            }

            & > .addToBagButton {
          
            }
            
            .basketForm {
                flex: 0 0 100%;
            }

            .basketButtonRow {
               
                .rowInput {
                    flex: 0 0 80px;
                    padding-right: 15px;

                    .mNumberInput input {
                        min-height: 41px !important;
                        height: 41px !important;
                    }
                }

                .rowButton {
                    
                    .addToBagButton {
                        .rems(17);
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }

        .productSizes {
            
            & > label {
                display: none;
            }
            
            .chosen-container {
                
                max-width: 100%;


            }

        }
        
        .button {
            &.noMargin {
                margin: 0;
            }
        }
        
        
    }
    

</style>
